import './App.css';


function App({ children }) {
  return (
    <div className="App">
      App
    </div>
  );
}

export default App;
